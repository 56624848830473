import React from "react";
import "../assets/Footer.css";

export default function Footer({ hideFooterPanel = false }) {
  return (
    <footer data-stellar-background-ratio="0.5">
      <div className="container">
        {!hideFooterPanel && (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="footer-bottom-nopanel">
                <div className="col-md-6 col-sm-5">
                  <div className="copyright-text">
                    <p>Places Medellín 2023</p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-7">
                  <div className="phone-contact-np">
                    <p>
                    </p>
                  </div>
                  <ul className="social-icon-np">
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
}
