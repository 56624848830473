import React, { useEffect, useState, useRef } from 'react';

export default function GoogleTranslate() {
  const [isRendered, setIsRendered] = useState(false);
  const googleTranslateElementRef = useRef(null);

  useEffect(() => {
    if (!isRendered) {
      // Define the callback function for initializing the Google Translate Element
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            includedLanguages: 'es,en',
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false,
          },
          'google-translate-element'
        );
      };

      // Load the Google Translate API
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.body.appendChild(script);

      // Clean up the script tag when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isRendered]);

  useEffect(() => {
    const removeLogo = () => {
      const googleTranslateLogo = document.querySelector('.goog-logo-link');
      if (googleTranslateLogo) {
        googleTranslateLogo.style.display = 'none';
      }
    };

    removeLogo();
    setIsRendered(true);
  }, []);

  useEffect(() => {
    const handleTranslateClick = (event) => {
      event.preventDefault(); // Prevent the default scroll behavior

      // Scroll to the top of the Google Translate component instead
      const yOffset = -googleTranslateElementRef.current.getBoundingClientRect().top;
      window.scrollBy({ top: yOffset, behavior: 'smooth' });
    };

    const googleTranslateElement = document.getElementById('google-translate-element');
    if (googleTranslateElement) {
      googleTranslateElement.addEventListener('click', handleTranslateClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (googleTranslateElement) {
        googleTranslateElement.removeEventListener('click', handleTranslateClick);
      }
    };
  }, []);

  return <div id="google-translate-element" ref={googleTranslateElementRef}></div>;
}
