import React, { useState, useEffect, useRef } from "react";
import "../assets/Features.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon, faLocation } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";


export default function Features({ placesData }) {
  const [placeDetail, setPlaceDetails] = useState();
  const [randomPlaces, setRandomPlaces] = useState([]);
  const [places, setPlaces] = useState([]);
  const [intervalIds, setIntervalIds] = useState({});
  const [showModal, setshowModal] = useState(false);
  const { id } = useParams();
  const observer = useRef(null);
  const [copyLink, setCopyLink] = useState(false);

  const handleModalOpen = (place) => {
    const randomIndexes = [];
    const selectedPlaces = [];
    setPlaceDetails(place);
    if (placesData?.length > 1) {
      if (placesData?.length <= 3) {
        while (randomIndexes?.length < placesData?.length) {
          const randomIndex = Math.floor(Math.random() * placesData?.length);
          if (!randomIndexes.includes(randomIndex)) {
            randomIndexes.push(randomIndex);
            selectedPlaces.push(placesData[randomIndex]);
          }
        }
      } else {
        while (randomIndexes?.length < 3) {
          const randomIndex = Math.floor(Math.random() * placesData?.length);
          if (!randomIndexes.includes(randomIndex)) {
            randomIndexes.push(randomIndex);
            selectedPlaces.push(placesData[randomIndex]);
          }
        }
      }
    }

    setRandomPlaces(selectedPlaces);
    setTimeout(() => {
      const myElement = document.getElementById(place.did);
      if (myElement) {
        myElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 700);
  };
  // Set the random places in the state
  useEffect(() => {
    setshowModal(false);
    setCopyLink(false);
    // if (places && places.length > 0) {
    //   const initialImages = places.map((place) => place?.mainImg);
    //   setCurrentImages(initialImages);
    // }
    // setPlaces(placesData);
    setPlaces(placesData);
    if (id) {
      const targetplace = placesData?.find((place) => place?.did === id);
      console.log(targetplace);
      if (targetplace) {
        handleModalOpen(targetplace);
        setshowModal(true);
        document.body.classList.add("modal-open");
      }
    }
  }, [placesData]);

  useEffect(() => {
    // setPlaces(placesData);

    // Update the observed elements when the places array changes
    if (observer.current) {
      placeRef.current.forEach((el) => {
        if (el) {
          observer.current.observe(el);
        }
      });
    }
  }, [placesData]);

  // const handleIfVisible = async (index) => {
  //   const updatedPlaces = [...places];
  //   const place = updatedPlaces[index];
  //   const newIntervalIds = { ...intervalIds };

  //   if (newIntervalIds[index]) {
  //     // If an interval already exists for the place, clear it before starting a new one
  //     clearTimeout(newIntervalIds[index]);
  //   }

  //   const intervalId = setTimeout(() => {
  //     const currentImageIndex = place.carousel.indexOf(place.currentImage);
  //     const nextImageIndex = (currentImageIndex + 1) % place.carousel.length;
  //     place.currentImage = place.carousel[nextImageIndex];
  //     setPlaces(updatedPlaces);

  //     // Recursively call handleMouseEnter after 2 seconds
  //     handleIfVisible(index);
  //   }, 700);

  //   newIntervalIds[index] = intervalId;
  //   setPlaces(updatedPlaces);
  //   setIntervalIds(newIntervalIds);
  // };
  const handleMouseOver = (index) => {
    const updatedPlaces = [...placesData];
    // Create a copy of the placesData array

    //  setPlaces([]);
    if (index >= placesData.length) {
      // Invalid index, return
      return;
    }

    const place = updatedPlaces[index];

    if (place.intervalId) {
      // If an interval already exists, return
      return;
    }

    if (place.carousel && place.carousel.length > 0) {
      const intervalId = setInterval(() => {
        const currentImageIndex = place.carousel.indexOf(place.currentImage);
        const nextImageIndex = (currentImageIndex + 1) % place.carousel.length;
        place.currentImage = place.carousel[nextImageIndex];
        setPlaces([...updatedPlaces]); // Update the state with the modified array
      }, 800);

      place.intervalId = intervalId; // Store the interval ID in the place object

      setPlaces([...updatedPlaces]); // Update the state with the modified array
    }
  };

  const placeRef = useRef([]);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      const { target, isIntersecting } = entry;
      const index = placeRef.current.indexOf(target);

      if (index !== -1) {
        const place = places[index];

        if (isIntersecting) {
          // Handle the logic when the element is intersecting
          handleMouseOver(index);
        } else {
          // Handle the logic when the element is not intersecting
          handleMouseLeave(index);
        }
      }
    });
  };

  useEffect(() => {
    if (window.innerWidth < 768 && placesData?.length > 1) {
      observer.current = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      });

      // Observe the initial elements
      placeRef.current.forEach((el) => {
        if (el) {
          observer.current.observe(el);
        }
      });
      // Clean up the IntersectionObserver when the component unmounts
      return () => {
        if (observer.current) {
          observer.current.disconnect();
          observer.current = null;
        }
      };
    } else if (window.innerWidth < 768 && placesData?.length == 1) {
      handleMouseOver(0);
    }
  });

  const handleMouseLeave = (index) => {
    const updatedPlaces = [...places];
    const place = updatedPlaces[index];
    place.currentImage = place.mainImg;
    if (place.intervalId) {
      // If an interval exists, clear it
      clearInterval(place.intervalId);
      delete place.intervalId; // Remove the interval ID from the place object
    }

    setPlaces([...updatedPlaces]);
  };

  const onNameClick = (e) => {
    e.preventDefault();
  };

  const handleCopy = (id) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(window.location.hostname + "/" + id).then(
        function () {
          setCopyLink(true)
        },
        function (err) {
          console.log("Could not copy link.");
        }
      );
    } else {
      // Clipboard API is not available, fall back to older method
      const tempInput = document.createElement("input");
      document.body.appendChild(tempInput);
      tempInput.value = "dsdsd";
      tempInput.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        console.log("Copy command was " + msg);
      } catch (err) {
        console.log("Could not copy text");
      }
      document.body.removeChild(tempInput);
    }
  };

  return (
    <section id="features" data-stellar-background-ratio="0.5">
      <div className="container">
        <div
          className="row"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            className="col-md-12 col-sm-12 col-12"
            style={{ width: "100%", marginBottom: "15px" }}
          >
            <div className="section-title">
              {placesData?.length > 0 ? (
                <>
                  <h2>Let's Visit</h2>
                  <span className="line-bar">...</span>
                </>
              ) : (
                <h4>No places to show. Please try again.</h4>
              )}
            </div>
          </div>
          {placesData?.map((place, index) => (
            <div className="col-md-6 col-sm-6" id={place.did} key={index}>
              {/* BLOG THUMB */}
              <div
                className="media blog-thumb"
                ref={(el) => (placeRef.current[index] = el)}
                onMouseEnter={() => handleMouseOver(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div className="media-object media-left">
                  {place.currentImage ? (
                    <a
                      href={place.currentImage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ transition: " opacity 0.3s ease-in-out" }}
                        src={place.currentImage}
                        className="img-responsive-place"
                        alt=""
                      />
                    </a>
                  ) : (
                    <a
                      href={place?.mainImg}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ transition: " opacity 0.3s ease-in-out" }}
                        src={place?.mainImg}
                        className="img-responsive-place"
                        alt=""
                      />
                    </a>
                  )}
                </div>
                <div className="media-body blog-info">
                  <small>
                    <FontAwesomeIcon
                      icon={place?.plan === "day" ? faSun : faMoon}
                    />{" "}
                    &nbsp;
                    {place?.activity.charAt(0).toUpperCase() +
                      place?.activity.slice(1)}{" "}
                    - &nbsp;
                    {place?.plan.charAt(0).toUpperCase() + place?.plan.slice(1)}
                  </small>
                  <h3>
                    <a href="/" onClick={onNameClick} className="place-name">
                      {place?.name}
                    </a>
                  </h3>
                  <p title={place?.desc} className="place-desc">
                    {place?.desc.length <= 150
                      ? place?.desc
                      : place?.desc.slice(0, 150) + "..."}
                  </p>
                  <a
                    className="btn btn-danger"
                    style={{
                      marginTop: "25px",

                      marginRight: "10px",
                    }}
                    href={place?.location}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLocation} /> Location on Google.
                  </a>

                  <a
                    className="btn btn-primary"
                    style={{
                      marginTop: "25px",

                      marginRight: "10px",
                    }}
                    href="#"
                    data-toggle="modal"
                    data-target="#myModal"
                    onClick={() => handleModalOpen(place)}
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={showModal ? "modal displaymodal" : "modal fade"}
        id="myModal"
        tabIndex={-1}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            setshowModal(false);
            setCopyLink(false);
            document.body.classList.remove("modal-open");
          }
        }}
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div
                  className="col-xs-4 col-sm-4"
                  style={{ height: "500px", overflowY: "scroll" }}
                >
                  <a href={placeDetail?.mainImg} target="_blank" rel="noopener noreferrer">
                    <img
                      src={placeDetail?.mainImg}
                      style={{ marginTop: "15px", padding: "0px" }}
                      className="col-xs-12 my-2 img-rounded"
                      alt=""
                    />
                  </a>
                  {placeDetail?.carousel?.map((image, index) => {
                    return (
                      <>
                        <a href={image} target="_blank" rel="noopener noreferrer">
                          <img
                            key={index}
                            src={image}
                            style={{ marginTop: "15px", padding: "0px" }}
                            className="col-xs-12 my-2 img-rounded"
                            alt=""
                          />
                        </a>
                      </>
                    );
                  })}
                </div>
                <div className="col-xs-8 col-sm-8">
                  <div className="col-xs-12 text-left">
                    <button
                      onClick={() => {
                        setshowModal(false);
                        setCopyLink(false);
                        document.body.classList.remove("modal-open");
                      }}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h3>
                      <a href="/" onClick={onNameClick} className="place-name">
                        {placeDetail?.name}
                      </a>
                    </h3>
                    <small className="text-danger">
                      <FontAwesomeIcon
                        icon={placeDetail?.plan === "day" ? faSun : faMoon}
                      />{" "}
                      &nbsp;
                      {placeDetail?.activity.charAt(0).toUpperCase() +
                        placeDetail?.activity.slice(1)}{" "}
                      - &nbsp;
                      {placeDetail?.plan.charAt(0).toUpperCase() +
                        placeDetail?.plan.slice(1)}
                    </small>

                    <p>{placeDetail?.desc}</p>
                    <br/>
                    {randomPlaces.length > 0 && (
                    <p
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#ebe9e982",
                        width: "fit-content",
                      }}
                      className="rounded text-center  text-white simlarplaces"
                    >
                      Similar Places
                    </p>)}
                  </div>

                  <div
                    className="row simlarplaces"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "stretch",
                    }}
                  >
                    {randomPlaces?.map((random) => {
                      return (
                        <div className="col-md-4 relatedplaces">
                          <div
                            role="button"
                            onClick={() => handleModalOpen(random)}
                            style={{
                              padding: "10px",
                              height: "100%",
                              borderRadius: "14px",
                              boxShadow: "1px 1px 10px 1px rgba(0, 0, 0, 0.3)",
                            }}
                            className="col-xs-12 col-sm-12 height-100 panel panel-default rounded"
                          >
                            <div className="col-12">
                              <img
                                style={{ padding: "0px", marginBottom: "10px" }} // Added marginBottom for the gap
                                src={random.mainImg}
                                className="col-xs-12 my-2  img-rounded"
                              />
                            </div>
                            <div>
                              <h6 className="panel-title text-center text-black">
                                {random?.name}
                              </h6>
                              <div className="pt-3 text-center small">
                                <p>
                                  <FontAwesomeIcon
                                    icon={
                                      random?.plan === "day" ? faSun : faMoon
                                    }
                                  />{" "}
                                  &nbsp;
                                  {random?.activity.charAt(0).toUpperCase() +
                                    random?.activity.slice(1)}{" "}
                                  - &nbsp;
                                  {random?.plan.charAt(0).toUpperCase() +
                                    random?.plan.slice(1)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <br />
                  <br />
                  <div className="mt-auto text-right">
                    <a
                      href={placeDetail?.location}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="button"
                      style={{
                        marginTop: "25px",

                        marginRight: "10px",
                      }}
                      className="btn btn-danger me-3"
                    >
                      <FontAwesomeIcon icon={faLocation} /> Location on Google.
                    </a>
                    <button
                      style={{
                        marginTop: "25px",
                        marginRight: "10px",
                      }}
                      type="button"
                      onClick={() => handleCopy(placeDetail?.did)}
                      className={copyLink ? "btn btn-success copyButton" : "btn btn-primary copyButton"}
                      data-text={"dsdsdsd"}
                    >
                      {copyLink ? "Copied" : "Share"}
                    </button>
                  </div>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
